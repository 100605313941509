declare var require: any;

export const environment = {
  production: true,
  apiUrl: '/sca2service/api',
  apiAuditoriaUrl: '/sca2auditoria/api',
  auth: {
      baseUrl: '/sca2service/api',
      authUrl: '/login/cas',
      loginUrl: '/login/cas',
      logoutUrl: '/cas/logout',
      detailsUrl: '/api/user/details',
      tokenValidationUrl: '/api/token/validate',
      storage: sessionStorage,
      tokenStorageIndex: 'token',
      userStorageIndex: 'user',
      loginSuccessRoute: '/#/login-success'
  },
  VERSION:  require('../../package.json').version
};
